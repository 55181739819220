.carousel{
    align-items: center;
    justify-content: space-between;
    gap:2rem;
    max-width:100%;
}
.carousel__nav__button{
    cursor:pointer;
    z-index: var(--z-tooltip);
}
.carousel__button{
    padding:0.5rem 2.2rem;
}
.carousel__body{
    overflow: hidden;
    max-width: 1000px;
}
.carousel__body__container{
    transition: transform .5s linear;
    
}
.carousel__body__container>div{
    min-width: 100%;
}
@media(max-width:1100px){
    .carousel{
        gap:0;
    }
}
@media(max-width:700px){
    .carousel{
        position: relative;
    }
    .carousel__nav__button{
        position:absolute;
        bottom: 5px;
    }
    .carousel .button--left{
        left: 5%;
    }
    .carousel .button--right{
        right: 5%;
    }
}
.projectsContainer{
    background-color: var(--background-color);
    border-radius:10px;
    padding:5.6rem 2.13rem;
}
.carousel{
    margin:0 0 4rem;
}
.project__content{
    border-top: 1px solid var(--gray-color);
    padding: 2rem 0;
}
@media(max-width:1100px){
    .projects{
        background-position: left;
    }
    .project__content{
        display: none;
    }
}
@media(max-width:700px){
    .carousel{
        margin:4rem 0 2rem;
    }
}
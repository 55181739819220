.skill{
    flex-direction: column;
    align-items: center;
}
.skill__icon{
    height: 4.13rem;
    width: auto;
    margin-bottom:.5rem;
}
@media(max-width:1100px){
    .skill{
        width: 4.8rem;
    }
}
@media(max-width:700px){
    .skill{
        width: auto;
    }
}

.form{
    position: relative;
}
.contactForm{
    flex-direction: column;
    align-items: center;
}

.formInputs{
    width: 100%;
    justify-content: space-between;
    gap:3rem;
    margin-bottom: 2rem;
}
.reminderInfoContainer, .reminderMessageContainer{
    width: 50%;
}
.reminderInfoContainer>.inputContainer:first-child{
    margin-bottom: 1rem;
}
.reminderMessageContainer{
    flex-direction: column;
    justify-content: stretch;
}
.contactForm label{
    display: block;
    margin-bottom: .5rem;
    font-weight: var(--font-medium);
}
.contactForm input, .contactForm textarea{
    width: 100%;
    background-color: var(--gray-color--light);
    border-radius: 8px;
    border: none;
    padding:.5rem 1rem;
    outline: none;
}
.contactForm textarea{
    height: 100%;
}
.contactForm .button{
    font-weight: var(--font-semibold);
}
@media(max-width:700px){
    .formInputs{
        flex-direction: column;
        gap:1rem;
    }
    .reminderInfoContainer, .reminderMessageContainer{
        width: 100%;
    }
}
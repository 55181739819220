.skills{
    background-color: #eee;
}
.skills__grid{
    grid-template-columns: repeat(8, 1fr); 
}
.skillCard.webSkills{
    grid-column: 1/8;
    border-bottom: 1px solid var(--gray-color);
    padding: 0 0 2rem;
}
.designSkills{
    grid-column: 1/4;
    grid-row: 2/3;
}
.wordpressSkills{
    grid-column:5/8;
    grid-row: 2/3;
}

@media(max-width:1100px){
    .skills__grid{
        grid-template-columns: 1fr;
        row-gap:2rem 
    }
    .webSkills, .designSkills, .wordpressSkills{
        grid-column: 1;
        grid-row:auto;
    } 
    .designSkills{
        border-bottom: 1px solid var(--gray-color);
    }
}
@media(max-width:700px){
}
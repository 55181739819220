.tabProject{
    flex-direction: column;
    align-items: center;
}
.tabProject__description{
    text-align: center;
    margin-bottom:2.5rem;
    font-weight:var(--font-semibold);
    font-size: 1.1rem;
}

.card{
    background-color:var(--second-color--light);
    padding: 2.74rem 1.53rem;
    border-radius: 8px;
}
.card__iconContainer{
    justify-content: flex-end;
    width: 100%;
}
.card__icon{
    margin-bottom: 2.24rem;
}
.card__title{
    margin-bottom:1rem;
}
.card__text{
    margin-bottom:1.86rem;
}
@media(max-width:1100px){
    
}

.tab{
    padding:.5rem 1.5rem;
    transition: border .1s linear;
    cursor:pointer;
    position: relative;
}
/*.tab--active{
    border-bottom: 6px solid var(--first-color);
}*/
.tab--active::before{
    width:100%;
    height:6px;
    background-color: var(--first-color);
    bottom:-6px;
    left:0;
    transition: .1s linear;
}
.tab span{
    text-align: center;
    font-weight: var(--font-normal);
    font-size: 1.3rem;
}
.tab--active span{
    text-shadow:
    -.1px -.1px 0 #000000,
    .1px -.1px 0 #000000,
    -.1px .1px 0 #000000,
    .1px .1px 0 #000000;
}
@media(max-width:700px){
    .tab span{
        font-size: 1.2rem;
    }

}
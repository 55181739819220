.services{
    background-color: var(--second-color);
}
.services__cards{
    gap:3.6rem;
}
@media(max-width:1100px){
    .services__cards{
        gap:2.2rem;
        flex-wrap: wrap;
    }
}
.carousel__item{
    align-items: center;
    justify-content: space-between;
    gap:4rem;
    padding: 0 2rem;
}
.project__link{
    display: none;
}
@media(max-width:1100px){
    .carousel__item{
        flex-direction: column-reverse;
    }
    .project__link{
        display: block;
        padding: 0.73rem 2rem;
    }
}
@media(max-width:700px){
    .carousel__item{
        gap:2rem;
        padding: 0;
    }
    .carousel__project__banner{
        width: 60%;
        margin-bottom: 1.5rem;
    }
}
.skillCard{
    padding:2rem 0;
    position:relative;
}
.skillIconsList{
    justify-content: space-around;
}
.skillCard::before{
    right:-3.5rem;
    height:90%;
    width: 1px;
    background-color: var(--gray-color);
    top:0;
}
@media(max-width:1100px){
    .skillIconsList{
        flex-wrap: wrap; 
        justify-content: flex-start;
        gap:2rem;
    }
    .skillCard{
        padding:0 0 2rem;
    }
    .skillCard::before{
        display: none;
    }
}
@media(max-width:700px){
    .skillIconsList{
        flex-wrap: wrap; 
        justify-content: flex-start;
        gap:1.5rem;
    }
}
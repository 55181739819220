.arrowLink{
    align-items: center;
    gap:.8rem;
    color:var(--first-color);
}
.arrowLink__icon{
    transition: transform .2s linear;
}
.arrowLink:hover .arrowLink__icon{
    transform: translateX(.25rem);
}
.contact__content{
    grid-template-columns: 2fr 1fr;
}
.contactForm__container{
    padding-right: 4rem;
    border-right: 1px solid var(--gray-color);
}
.contactSocial__container{
    padding-left: 4rem;
}

@media(max-width:1100px){
    .contact__content{
        grid-template-columns: 1fr;
    }
    .contactForm__container{
        padding-right: 0;
        border-right: none;
        margin-bottom: 4rem;
    }
    .contactSocial__container{
        padding-left: 0;
    }
}
@media(max-width:700px){
    .contact .socialIcons{
        justify-content: center;
    }
}
.tabTable{
    
}
.tabTable__tabs{
    justify-content: center;
    gap: 7rem;
    margin-bottom: 2rem;
    font-family: var(--tittle-font);
}
.tabTable__content{
    /*position:relative;*/
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
}
@media(max-width:1100px){
    .tabTable__tabs{
        flex-direction: column;
        align-items: center;
        gap:1.5rem;
    }
}
@media(max-width:700px){
    .tabTable__tabs{
        flex-direction: column;
        align-items: center;
    }
}